import React from 'react'
import MainNav from './main-navigation'

function Header() {
  return (
    <header>
      <MainNav />
    </header>
  )
}
export default Header
